import React from 'react'
import Paper from '@mui/material/Paper'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import classes from './Progress.module.css'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: 'black',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: 'white',
    },
}));


const ProgressBar = ({ heading, maxVal, curVal, val, isMob }) => {
    return (
        <Paper className={classes.progBarWrap}>
            <div className={classes.progTextWrap}>
                <div className={classes.progBarTxtHeading}>{heading}</div>
                <div>{`${curVal}/${maxVal}`}</div>
            </div>
            <BorderLinearProgress variant="determinate" value={val} />
        </Paper>
    )
}

export default ProgressBar

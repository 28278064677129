import React from 'react'
import { Paper, Divider } from '@mui/material';
import resultClass from './Results.module.css'

const ResultBar = ({ resultBarVals, isMobile }) => {

    return (
        <div className={resultClass.resultBarContainer}>
            <Paper
                sx={{
                    padding: 3,
                    width: isMobile ? 'auto' : 'fit-content',
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    alignItems: isMobile ? 'flex-start' : 'center',
                    marginBottom: isMobile ? '15px' : 'none'
                }}
            >
                {
                    resultBarVals.map((each, index) => (
                        <>
                            <div key={index} className={resultClass.resultBarElement}>
                                <div className={resultClass.resultBarEleTitle}>{each.title}</div>
                                <div className={resultClass.resultBarEleVal}>{each.val}</div>
                            </div>
                            {index !== 4 &&
                                <Divider
                                    orientation={isMobile ? "horizontal" : "vertical"}
                                    variant="fullwidth"
                                    flexItem
                                    sx={{ padding: isMobile ? 0 : 1 }}
                                />
                            }
                        </>
                    ))
                }
            </Paper>
        </div>
    )
}

export default ResultBar

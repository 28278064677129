/* eslint-disable no-undef */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Paper from '@mui/material/Paper'
import Button from '../UI/Button'
import api from '../../shared/apiSetup'
import { QUESTION, RESULTS } from '../../constants/RouteConst'
import { setWPMHistogramData } from '../../store/actions/resultActions'
import ProgressWPM from '../UI/ProgressCard/ProgressWPM'
import { getResultsData } from '../../shared/apiUtil'
import testClass from './Test.module.css'
import useWebSocket from './useWebSocket';
import { getEvtData, calculateAverage } from '../../shared/Utility'

let logDataToBeSubmitted = []
let keys = {};

const Test = () => {
    const dispatch = useDispatch();

    const sentenceList = useSelector(state => state.test.sentenceList);
    const isMobile = useSelector(state => state.home.isMobileDev);
    const uid = useSelector(state => state.user.uid);
    const continueCode = useSelector(state => state.user.continueCode);
    // const appLang = useSelector(state => state.user.appLang);
    const testPageLang = useSelector(state => state.user.testPageLang);
    const wpmHistData = useSelector(state => state.results.wpmHistogramData)

    const [firstKeyPressTime, setFirstKeyPressTime] = useState(null);
    const [lastKeyPressTime, setLastKeyPressTime] = useState(null);
    // const [nextClick, setNextClick] = useState(false);
    const [inputVal, setInputVal] = useState('');
    const [sentenceArr, setSentenceArr] = useState(sentenceList)
    const [sentenceIndex, setSentenceIndex] = useState(0)
    const [wpm, setWpm] = useState(0)
    const [wpmList, setWpmList] = useState([])
    const [errorRate, setErrRate] = useState(0)
    const [errorRateList, setErrRateList] = useState([])
    const [tsId, setTsId] = useState(null)

    const navigate = useNavigate();
    const inputRef = useRef(null);

    const btnData = {
        btnName: testPageLang.next,
        width: 125
    }

    useEffect(() => {
        setSentenceArr(sentenceList)
    }, [sentenceList])

    // Called only when the "continue type 15 more sentences is chosen". 
    useEffect(() => {
        if (wpmHistData) {
            setWpm(parseInt(wpmHistData.avgWPM)) // WPM on the ProgressWPM component = average WPM of 15 sentences already typed in the current session
            setErrRate(parseInt(wpmHistData.avgErrRate)) // Err rate of the 15 sentneces already typed in the current session.
        }

    }, [wpmHistData])


    // Called each time a new sentence is loaded on the screen. 
    const addTestSection = async () => {
        const tsData = {
            sentenceId: sentenceList[sentenceIndex].id,
            continueCode: continueCode,
            participantId: uid
        }

        try {
            const response = await api.post('/test/addTestSection', tsData); // creates a new row for each sentence in the test_sections table
            if (response.data.id) {
                return Promise.resolve(response.data.id);
            } else {
                return Promise.reject("Response does not contain test section ID");
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }

    useEffect(() => {
        if (sentenceIndex >= 0 && sentenceIndex < 15) {
            addTestSection(false).then((testSectionId) => {
                setTsId(testSectionId)
            }).catch((error) => {
                console.error('Error:', error);
            });
        }
    }, [sentenceIndex])


    const handleKeyLog = (event) => {

        const currentTime = Date.now();

        // Check if this is the first key press
        if (firstKeyPressTime === null) {
            setFirstKeyPressTime(Date.now())
        }
        // Always update the last key press timestamp
        setLastKeyPressTime(Date.now());
        const evtData = getEvtData(tsId, event, currentTime, inputVal, keys, false) // get the event data object for each key entered.
        if (evtData) {
            logDataToBeSubmitted.push(evtData); // add it to the array of event data objects
        }
    };

    useEffect(() => {
        const inputElement = inputRef.current;

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                // e.preventDefault()
                handleNext(e.srcElement.value, true)
            } else {
                keys[e.which] = true;
                handleKeyLog(e)
            }
        }

        const handleKeyUp = (e) => {
            if (e.key !== 'Enter') {
                keys[e.which] = false;
                handleKeyLog(e)
            }
        }

        if (inputElement) {
            inputElement.addEventListener('keydown', handleKeyDown);
            //Deprecated feature
            // inputElement.addEventListener('keypress', handleKeyPress);
            inputElement.addEventListener('keyup', handleKeyUp);
        }

        return () => {
            if (inputElement) {
                inputElement.removeEventListener('keydown', handleKeyDown);
                inputElement.removeEventListener('keyup', handleKeyUp);
            }
        };
    }, [tsId, firstKeyPressTime, lastKeyPressTime])

    const submitLogData = async () => {
        if (logDataToBeSubmitted.length > 0) {
            try {
                const response = await api.post('/test/storeLogData', logDataToBeSubmitted); // updates event data values of all the entered keys to the log_data table
                return true
            } catch (error) {
                console.error('Error:', error);
            }
        }
    }

    const onInputChange = (e) => {
        e.preventDefault()
        const evtData = getEvtData(tsId, e, Date.now(), e.target.value, keys, true)
        if (evtData) {
            logDataToBeSubmitted.push(evtData);
        }
        setInputVal(e.target.value)
    }


    const handleTestComplete = async (avgWPMVal) => {
        const response = await api.post('/test/updateAvgWPM', avgWPMVal); // Calculates average WPM of all 15 sentences. 
        if (wpmHistData) { // wpmHistData is present when the continue typing 15 sentences button is clicked on the result page
            getResultsData(uid)
                .then(resultData => {
                    // Handle the result object here
                    dispatch(setWPMHistogramData(resultData))
                    navigate(RESULTS);
                })
                .catch(error => {
                    // Handle any errors here
                    console.error(error);
                });
        } else {
            navigate(QUESTION);
        }
    }

    const handleWebSocketMessage = useCallback(
        (data) => {
            if (data.type === "wpmCalculation") {
                const newWpmList = [...wpmList, parseInt(data.wpm)];
                const avgWPM = calculateAverage(newWpmList)

                const newErrRateList = [...errorRateList, data.errRate];
                const avgErrRate = calculateAverage(newErrRateList)

                setWpmList(newWpmList)
                setWpm(parseInt(avgWPM))

                setErrRate(parseInt(avgErrRate))
                setErrRateList(newErrRateList)

                // Reset local state values before proceeding to the next sentence
                setInputVal('')
                setFirstKeyPressTime(null)
                setLastKeyPressTime(null)


                if (sentenceIndex < 14) {
                    logDataToBeSubmitted = [] // Reset keyloag array 
                    setTsId(null)             // Reset tsIt
                    setSentenceIndex(sentenceIndex + 1) // Proceed to display the next sentence
                } else { // Indicates that all 15 sentneces have been submitted
                    const avgWPMVal = { uid: uid } // 
                    handleTestComplete(avgWPMVal)
                }
                // setNextClick(false)
            } else if (data.error) {
                console.error("WebSocket error:", data.error);
            }
        },
        [wpmList, sentenceIndex, navigate]
    );

    const { sendJsonMessage, reconnectWs, isOpen } = useWebSocket("wss://typingtest.cs.uni-saarland.de:8001/ws", handleWebSocketMessage);


    const submitUserInput = async (inputValue) => {
        if (inputValue.length > 0) {
            const userInputData = {
                tsId: tsId,
                inputVal: inputValue,
            }
            const wpmData = {
                inputVal: inputValue,
                inputTime: (lastKeyPressTime - firstKeyPressTime) / 1000,
                tsId: tsId,
                device: isMobile ? 'mobile' : 'desktop',
                sentenceVal: sentenceArr[sentenceIndex].sentence
            }
            sendJsonMessage(wpmData);

            try {
                const resp = await api.post('/test/submitUserInput', userInputData);
            } catch (error) {
                console.error('Error:', error);
            }
        }
    }

    const handleNext = (inputData, isEnter) => {
        const result = submitLogData()
        const inputValue = isEnter ? inputData : inputVal
        result.then(() => {
            // The promise has resolved
            submitUserInput(inputValue);
        });
    }

    return (
        <div className={testClass.testContainer}>
            <div className={testClass.testHeaderWrap}  >
                <div className={testClass.testInstr}>
                    {testPageLang.instructions}
                </div>
                <ProgressWPM
                    heading="Sentences"
                    maxVal={15}
                    curVal={sentenceIndex + 1}
                    errRate={errorRate}
                    wpm={wpm}
                    isMob={isMobile}
                    langData={testPageLang}
                />
            </div>
            <div className={testClass.testContentWrap} >
                <div>
                    <div className={testClass.testContent}>
                        <Paper className={testClass.testInputSectionWrap}>
                            <div
                                style={{ fontSize: isMobile ? 20 : 28 }}>
                                {(sentenceArr.length > 1) && sentenceArr[sentenceIndex].sentence}
                            </div>
                            <div className={testClass.testInputWrap}>
                                <input
                                    ref={inputRef}
                                    type="text"
                                    autoFocus
                                    placeholder="Type here..."
                                    className={testClass.testInput}
                                    value={inputVal}
                                    onChange={(e) => onInputChange(e)}
                                />
                            </div>
                        </Paper>
                    </div>
                    <div className={testClass.nextBtnWrap}>
                        <Button btnData={btnData} handleBtnClick={() => handleNext(null, false)} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Test

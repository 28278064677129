export const histLayout = (histogramData, isMobile) => ({
  // width: isMobile ? 500 : 900,
  // height: isMobile ? 200 : 600,
  autosize: true,
  margin: {
    l: 40,
    r: 20,
    b: 80,
    t: 20,
    pad: 0
  },
  // title: 'Normalized Histogram',
  xaxis: {
    dtick: isMobile ? 10 : 5,
    range: [0, histogramData.maxBin],
    title: 'Value',
    // tickvals: histogramData.xTicks,
    // ticktext: histogramData.xTicks.map(val => val.toString()),
    tickangle: 0,
    ticklen: 5,
    tickfont: {
      size: 8    // font-size
    }
  },
  yaxis: {
    title: 'Percentage (%)', tickfont: {
      size: 10
    }
  },
})


export const lineOptions = (allWPM) => ({
  scales: {
    x: {
      type: "linear", // Use a categorical axis for Sentence IDs
      grid: {
        display: false,
      },
      ticks: {
        stepSize: 1,
        min: 1,
        max: allWPM.length, // Adjust this value based on the number of sentences
        maxTicksLimit: allWPM.length,
      },
      title: {
        display: true,
        text: 'Sentence'
      },
    },
    y: {
      type: "linear",
      position: "left",
      grid: {
        display: true,
      },
      ticks: {
        stepSize: 10,
        min: 0,
        max: 100, // Adjust this value based on your WPM data range
      },
      title: {
        display: true,
        text: 'WPM'
      },
    }
  },
  plugins: {
    legend: {
      display: false,
    },
  }
})

export const resultBarData = (isMobile, lang, wpm, backspace, errRate) => ([
  {
    title: "Device",
    val: isMobile ? 'Mobile' : 'Desktop'
  },
  {
    title: "Language",
    val: lang
  },
  {
    title: 'Words Per Minute',
    val: wpm
  },
  {
    title: 'Backspace',
    val: backspace
  },
  {
    title: 'Error Rate',
    val: errRate
  },
])

export const resHighData = (restultPageLang, histogramData ) => ([
  {
    heading: restultPageLang.maxwpm.heading,
    errors: restultPageLang.maxwpm.errors,
    errVal: histogramData.maxWPM[1],
    wpm: restultPageLang.maxwpm.wpm,
    wpmVal: parseInt(histogramData.maxWPM[2]),
    sentence: histogramData.maxWPM[0]
  },
  {
    heading: restultPageLang.minWpm.heading,
    errors: restultPageLang.minWpm.errors,
    errVal: histogramData.minWPM[1],
    wpm: restultPageLang.minWpm.wpm,
    wpmVal: parseInt(histogramData.minWPM[2]),
    sentence: histogramData.minWPM[0]
  },
  {
    heading: restultPageLang.maxerrorrate.heading,
    errors: restultPageLang.maxerrorrate.errors,
    errVal: histogramData.maxErrRate[2],
    wpm: restultPageLang.maxerrorrate.wpm,
    wpmVal: parseInt(histogramData.maxErrRate[3]),
    sentence: histogramData.maxErrRate[0],
    correctSentence: histogramData.maxErrRate[1]
  }
])

export const instructionsData_de = {
    infobox: "Mit diesem Kurztest können Sie testen, wie schnell Sie auf Ihrem Gerät tippen können und sich mit anderen vergleichen. Die Daten werden für wissenschaftliche Zwecke gespeichert und ausgewertet und sind streng anonymisiert.",
    main: "Anweisungen",
    note: "Bitte lesen Sie die Anweisungen sorgfältig durch, bevor Sie fortfahren.",
    instructions1: "Der Tipptest dauert etwa 10 Minuten. Es werden Ihnen nacheinander 15 Sätze angezeigt. <b>Lesen Sie jeden Satz sorgfältig durch</b>, erst dann geben Sie ihn so schnell und korrekt wie möglich ein.",
    instructions2: 'Bei jedem Satz beginnt die Zeitmessung erst <b>nach dem ersten Tastendruck</b> und endet nach dem letzten eingegebenen Buchstaben. Drücken Sie die Eingabetaste ("Enter") oder klicken Sie auf die Schaltfläche "Weiter", um den nächsten Satz anzuzeigen.',
    instructions3: "Nutzen Sie die Tastatur so wie Sie es normalerweise auch tun. Sie können Autokorrektur, Autovervollständigung, Swype usw. verwenden.",
    instructions4: "Das Resultat im Vergleich zu anderen Nutzern erhalten Sie nach 15 getippten Sätzen und einem kurzen Fragebogen.",
    instructions5: "Um eine bessere Einschätzung Ihrer Tippgeschwindigkeit zu erhalten, können Sie weitere Sätze eingeben. Sie können den Test auch später wiederholen, es werden dann neue Sätze angezeigt.",
    instructions6: 'Bitte beachten Sie, dass eine langsame Netzwerkverbindung die Schaltfläche "Weiter" vorübergehend deaktivieren kann, bis der nächste Satz geladen ist.',
    start: "Test starten!"
}

export const consentData_de = {
    title: "",
    main: "<b>Einverständniserklärung</b>",
    introduction: "Diese Forschung wird an der Universität des Saarlandes in Deutschland durchgeführt. Der Zweck der Datenerhebung ist es, wissenschaftliche Untersuchungen über das Tippverhalten von Personen durchzuführen.",
    heading: "<b>Datenerfassung:</b>",
    subHeading: 'Durch Anklicken des Buttons "Akzeptieren" geben Sie Ihre Einverständniserklärung zur Datenerhebung (anonymisiert).',
    consent1: "Die erfassten Daten bestehen aus: 1) Tastenereignisse und die damit verbundenen Informationen, wie Zeit und gedrückte Taste, 2) Informationen über das Gerät und den Standort der Teilnehmer, 3) demografische Daten und Details über die Interaktion des Teilnehmers, wie vom Teilnehmer bereitgestellt.",
    consent2Heading: "Anonymität, sichere Speicherung, Vertraulichkeit:",
    consent2: "Wir sammeln Daten für Forschungszwecke, die im Rahmen unserer wissenschaftlichen Arbeit öffentlich zugänglich gemacht werden können. Daher befolgen wir die europäische Datenschutzverordnung (GDPR) bei der strikten Anonymisierung der veröffentlichten Daten. Diese personenbezogenen Daten sind vertraulich. Sie werden bis zu fünf Jahre lang gelagert und nicht außerhalb des EU/EWR-Raumes transferiert. Die veröffentlichten Daten werden keine expliziten Hinweise auf Ihre Identität enthalten.",
    consent3Heading: "Freiwillige Teilnahme:",
    consent3: "Die Teilnahme an der Studie ist freiwillig. Sie haben das Recht, die Teilnahme jederzeit abzubrechen, ohne verpflichtet zu sein, bestimmte Gründe anzugeben.",
    consent4Heading: "Rechte des Studienteilnehmers:",
    consent4: "Es kann notwendig sein, von den Rechten der betroffenen Person abzuweichen, wie sie in der GDPR und den nationalen Rechtsvorschriften definiert sind, wenn die Studie zur Erfüllung des öffentlichen Interesses durchgeführt wird und die Ausübung der Rechte des Teilnehmers das Erreichen des Forschungsziels verhindern würde. Von folgenden Rechten kann abgewichen werden:<ol><li>Das Recht auf Information zuzugreifen</li><li>Das Recht darauf Informationen zu berichtigen</li><li>Das Recht die Verarbeitung einzuschränken</li><li>Das Recht auf Vergessen</li></ol>Wenn es jedoch möglich ist, die Studienziele ohne wesentliche Behinderung zu erreichen, wird die Universität des Saarlandes Ihre Rechte im Sinne der GDPR verwirklichen. Der Umfang Ihrer Rechte hängt von der Rechtsgrundlage für die Verarbeitung Ihrer personenbezogenen Daten, der nationalen Gesetzgebung und der Ausübung Ihrer Rechte ab und erfordert einen Identitätsnachweis.",
    consent5: "Wir danken Ihnen für Ihren Beitrag zur Forschungsarbeit.<br><br>",
    name1: "Prof. Dr. Anna Maria Feit",
    affiliation1: "Universität des Saarlandes",
    email1: "feit@cs.uni-saarland.de"
}

export const instructionsData_en = {
    infobox: "This brief test allows you to test how fast you can type on your device and compare your speed with others. Data is collected and used for scientific purposes only and strictly anonymized.",
    main: "Instructions",
    note: "Please read carefully before continuing.",
    instructions1: "The typing test will take about 10 minutes of your time. You will be presented 15 sentences one by one, memorize it, then type it as fast and accurately as possible.",
    instructions2: 'For each sentence, <b> timing starts only after the first keystroke </b> and stops after the last one. Press the "Enter" key or click the "Next" button to type the next sentence.',
    instructions3: "Use the keyboard as you normally do.You can use autocorrection, autocompletion, swipe, etc.",
    instructions4: "You will get full statistics after completing 15 sentences and a short questionnaire.",
    instructions5: "To get a better estimate of your performance, you can continue typing more sentences.You can also redo the test later; every time there will be different sentences.",
    instructions6: 'Please, note, that slow network connection might momentarily disable the "Next" button until the next sentence is loaded.',
    start: "Start the test!"
}

export const consentData_en = {
    title: "Declaration of Consent",
    main: "<b>Informed Consent </b>",
    introduction: "This research is carried out in Saarland University. The purpose of the data collection is to conduct scientific research on people''s typing behavior.",
    heading: "<b>Collection of data:</b>",
    subHeading: 'By clicking the "Accept" button you give your informed consent for the data collection(anonymized).',
    consent1: "The data collected consists of: <ol><li> keypress events and their associated information, such as time and pressed key,</li> <li> information about the participant''s device and location, and </li> <li> demographic data and details about the participant''s interaction as provided by the participant. </li></ol>",
    consent2Heading: "Anonymity, secure storage, confidentiality:",
    consent2: "We collect data for research purposes, which can be made publicly available as part of our scientific work. Therefore, we follow European privacy regulation (GDPR) in strictly anonymizing the published data. This personally identifiable information is confidential. It will be stored for up to five years and will not be transferred outside EU/EEA area. No explicit clues of your identity will be left to the published data.",
    consent3Heading: "Voluntary participation:",
    consent3: "Participation in the study is voluntary. You have the right to discontinue participation at any time without obligation to disclose any specific reasons.",
    consent4Heading: "The rights of the study participant:",
    consent4: "It may be necessary to deviate from the rights of the data subject, as defined in GDPR and national legislation, if the study is being carried out for the performance of the public interest and the exercising of the participant''s rights would likely prevent reaching the aim of the research study. The following rights can be deviated from: <ol><li>The right to access data</li><li>The right to rectify information</li><li>The right to restrict processing</li><li>The right to be forgotten</li></ol>If, however, it is possible to achieve the aims of the study and the achievement of the purpose is not greatly hindered, Saarland University will actualize your rights as defined in the GDPR. The extent of your rights is related to the legal basis of processing of your personal data, national legislation and exercising your rights requires proof of identity.",
    consent5: "We thank you for your contribution to research efforts.<br><br>",
    name1: "Prof. Dr. Anna Maria Feit",
    affiliation1: "Universität des Saarlandes",
    email1: "feit@cs.uni-saarland.de"
}

export const pageData_en = {
    heading: "About the test",
    button: "START THE TEST"
}

export const pageData_de = {
    heading: "Über den Test",
    button: "STARTEN SIE DEN TEST"
}

export const consentForm_de = {
    heading: "Zustimmungsformular",
    button: "Akzeptieren"
}

export const consentForm_en = {
    heading: "Consent",
    button: "Accept"
}

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TEST } from '../../constants/RouteConst';
import { contactDetails } from '../../shared/Lang/contactUs';
import { setSentenceList } from '../../store/actions/testActions';
import { Paper } from '@mui/material';
import { copyToClipImg, arrow_left, arrow_right } from '../UI/Icons';
import FAQ from './FAQ'
import LineGraph from './LineGraph'
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Histogram from './Histogram';
import Button from '../UI/Button'
import api from '../../shared/apiSetup'
import Notification from '../UI/Snackbar'
import resultClass from './Results.module.css'
import ResultBar from './ResultBar';
import ResultHighlights from './ResultHighlights'
import { getResultsData } from '../../shared/apiUtil'
import Loader from '../UI/Progress'
import { resultBarData, resHighData } from './Util';

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const WpmHistogramChart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setInputVal] = useState('');
  const [copySuccess, setCopySuccess] = useState('');
  const [isNotif, setNotif] = useState(false);
  const [notifMsg, setNotifMsg] = useState('');
  const [prevIds, setPrevIds] = useState(null);
  const [histogramData, setResData] = useState(null);
  const [isPrevRes, setIsPrevRes] = useState(false);
  const [prevResIndex, setPrevResIndex] = useState(0);
  const [isLoader, setLoader] = useState(false);

  const resData = useSelector(state => state.results.wpmHistogramData);
  const continueCode = useSelector(state => state.user.continueCode);
  const isExistingCode = useSelector(state => state.user.isExistingCode);
  const restultPageLang = useSelector(state => state.user.restultPageLang);
  const isMobile = useSelector(state => state.home.isMobileDev);
  const lang = useSelector(state => state.user.appLang);
  const uid = useSelector(state => state.user.uid);

  const fetchPreviousSessions = async () => {
    setLoader(true)
    const continue_code = continueCode
    try {
      const response = await api.get(`/result/previous-sessions?continue_code=${continue_code}`);
      if (response.data.participant_ids.length > 1) {
        const filterArr = response.data.participant_ids.filter(each => each !== uid)
        setPrevIds(filterArr)
      }
      setTimeout(() => {
        setLoader(false)
      }, 2000);
    } catch (error) {
      console.error('Error fetching previous sessions:', error);
      throw error;
    }
  }

  useEffect(() => {
    setResData(resData)
  }, [resData])

  useEffect(() => {
    fetchPreviousSessions()
  }, [])


  const subHeading = {
    fontWeight: 500,
    fontSize: isMobile ? 16 : 20
  }

  const btnData = {
    btnName: "Continue",
    width: '100%',
    height: 50
  }

  const sendEmailBtn = {
    btnName: "Send",
    textAlign: 'center',
    width: '100%',
    height: 50
  }

  const prevResBtn = {
    btnName: isPrevRes ? "Today's results" : "Previous Results",
    textAlign: 'center',
    width: isMobile ? '100%' : '365px',
    height: 50
  }

  const onSendContCode = async () => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('uid', uid)
    formData.append('url', window.location.hostname)

    try {
      const response = await api.post('/result/sendContinueCode', formData);
      if (response.status === 200) {
        setNotif(true)
        setNotifMsg(response.data.message)
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const onContinueTest = async () => {
    const appLang = {
      lang: lang
    }
    try {
      const response = await api.post('/home/getSentences', appLang);
      dispatch(setSentenceList(response.data.sentenceList))
      navigate(TEST);
    } catch (error) {
      console.error('Error fetching string array:', error);
    }
    navigate(TEST);
  }

  const onInputChange = (e) => {
    setInputVal(e.target.value)
  }

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopySuccess("Copied to clipboard!");
        setTimeout(function () {
          setCopySuccess("");
        }, 3000);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const fetchResData = async (uid) => {
    getResultsData(uid)
      .then(resultData => {
        setIsPrevRes(true)
        setResData(resultData)
        setTimeout(() => {
          setLoader(false)
        }, 1200);
      })
      .catch(error => {
        console.error(error);
      });
  }

  const fetchPrevRes = (prevId) => {
    if (isPrevRes) {
      setIsPrevRes(false)
      setResData(resData)
      setPrevResIndex(0)
    } else {
      fetchResData(prevId)
    }
  }

  const onLeftArrowClick = () => {
    setLoader(true)
    if (prevResIndex > 0) {
      setPrevResIndex(prevResIndex - 1)
      fetchResData(prevIds[prevResIndex - 1])
    }
  }

  const onRightArrowClick = () => {
    setLoader(true)
    if (prevResIndex < prevIds.length - 1) {
      setPrevResIndex(prevResIndex + 1)
      fetchResData(prevIds[prevResIndex + 1])
    }
  }

  return (
    <div className={resultClass.resultsContainer}>
      {
        isNotif && <Notification isOpen={true} msg={notifMsg} notifType="success" isAutoHide={true} onNotifClose={() => setNotif(false)} />
      }
      <div className={resultClass.resultBarWrap}>
        {
          histogramData &&
          <ResultBar
            resultBarVals={
              resultBarData(
                isMobile,
                lang,
                Math.ceil(histogramData.avgWPM),
                histogramData.backspaceData.avg_backspace.toFixed(2),
                parseInt(histogramData.avgErrRate))
            }
            isMobile={isMobile}
          />
        }
        {
          prevIds &&
          <Button
            checkIsDisable={false}
            btnData={prevResBtn}
            handleBtnClick={() => fetchPrevRes(prevIds[0])}
          />
        }
        {
          isPrevRes && (
            <>
              {
                prevResIndex !== 0 && (
                  <img
                    src={arrow_left}
                    alt="left arrow"
                    className={resultClass.leftArrow}
                    onClick={() => onLeftArrowClick()}
                  />
                )
              }
              {
                prevResIndex !== (prevIds.length - 1) && (
                  <img
                    src={arrow_right}
                    alt="right arrow"
                    className={resultClass.rightArrow}
                    onClick={() => onRightArrowClick()}
                  />
                )
              }
              {
                isLoader && (
                  <div className={resultClass.loaderWrap}>
                    <Loader />
                  </div>
                )
              }
            </>
          )
        }
      </div>
      <div className={resultClass.continueCodeWrap}>
        <Paper sx={{ padding: isPrevRes ? 0 : 2, display: 'flex', flexDirection: 'column', marginTop: 2 }}>
          {
            !isPrevRes && (
              <>
                <div className={resultClass.continueTypTxt}>
                  Continue to type 15 new sentences to get a better estimate of your performance.
                </div>
                <Button
                  checkIsDisable={false}
                  btnData={btnData}
                  handleBtnClick={() => onContinueTest()}
                />
              </>
            )
          }
          {
            !isExistingCode && (
              <>
                <div>
                  <div className={resultClass.contCodeTxt}>Continue Code:</div>
                  - A unique code to track your typing performance across devices.<br />
                  - Use it to seamlessly continue tests on different devices.
                </div>
                <Divider variant="left" sx={{ marginTop: 2, marginBottom: 1 }} />
                <div style={{ fontWeight: 'bold', marginBottom: 10 }}>Your Continue Code:</div>
                <div>
                  <div className={resultClass.copyToClipWrap}>
                    {histogramData && histogramData.contCode}
                    {
                      histogramData &&
                      <img
                        className={resultClass.copyToClipIcon}
                        src={copyToClipImg}
                        alt="copytoclip"
                        onClick={() => copyToClipboard(histogramData.contCode)}
                      />
                    }
                  </div>
                  {copySuccess && <b>{copySuccess}</b>}
                </div>
                <Divider variant="left" sx={{ marginTop: 2, marginBottom: 1 }} />
                <div>
                  To receive your personalized link with the continue code, enter your email below:
                </div>
                <div className={resultClass.inputWrap}>
                  <input
                    // ref={inputRef}
                    type="email"
                    autoFocus
                    placeholder="name@example.com"
                    className={resultClass.emailInput}
                    value={email}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
                <Button
                  checkIsDisable={!emailRegex.test(email)}
                  btnData={sendEmailBtn}
                  handleBtnClick={() => onSendContCode()}
                />
              </>
            )
          }
        </Paper>
      </div>
      <Grid container spacing={6} sx={{ justifyContent: isPrevRes ? 'center' : 'left', opacity: isLoader ? '0.5' : 'none' }}>
        <Grid item xs={isMobile ? 12 : 8}>
          <div className={resultClass.graphHeading}>
            Your typing performance in comparison with the other desktop participants
          </div>
          {histogramData && (
            <>
              <Histogram
                globalWPM={histogramData.globalWPM}
                pid={prevIds ? prevIds[prevResIndex] : null}
                isPrevRes={isPrevRes}
                prevResIndex={prevResIndex}
              />
              <LineGraph
                avgWPM={parseInt(histogramData.avgWPM[0])}
                allWPM={histogramData.allWPM}
                globalWPM={histogramData.globalWPM}
                isMobile={isMobile}
                isPrevRes={isPrevRes}
                prevResIndex={prevResIndex}
              />
            </>
          )
          }
        </Grid>
      </Grid>
      {
        histogramData &&
        <ResultHighlights
          resHighData={resHighData(restultPageLang, histogramData)}
          histogramData={histogramData}
          isMobile={isMobile}
          isPrevRes={isPrevRes}
        />
      }
      {
        !isPrevRes && (
          <>
            <div>
              <FAQ faqData={restultPageLang.faq} isMobile={isMobile} />
            </div>
            <div>
              <h2 className={resultClass.contactUsHeading}>Contact Us:</h2>
              <div className={resultClass.contactUsWrap}>
                <div>
                  <div style={subHeading}>{contactDetails.name}</div>
                  <div style={{ fontSize: isMobile ? 16 : 20 }}><span style={subHeading}>Email: </span>{contactDetails.email}</div>
                </div>
              </div>
            </div >
          </>
        )
      }
    </div >
  );
};




export default WpmHistogramChart;

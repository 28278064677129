import { getGermanLanguageName, getEnglishLanguageName } from '../../shared/Utility'

export const initFormState = (uid, appLang) => ({
    uid: uid || null,
    age: null,
    gender: null,
    nativeLanguages: (appLang === 'English') ? getEnglishLanguageName() : getGermanLanguageName(),
    typeTestLanguage: null,
    keyboardType: null,
    keyboardTypeDetails: null,
    keyboardLayout: null,
    keyboardLayoutDetails: null,
    keyboardTypingTime: null,
    typingHabitFingers: null,
    typingHabitHands: null,
    typingSpeed: null,
    typingAccuracy: null,
    keyboardApp: null,
    keyboardAppYes: null,
    keyboardAppDetails: null,
    keyboardFeatures: null,
    keyboardFeaturesDetails: null,
    keyboardFeaturesWhy: null,
    keyboardFeaturesWhyDetails: null,
});

export const selectList = ['age', 'gender', 'nativeLanguages', 'typeTestLanguage', 'keyboardType', 'typingHabitHands', 'typingHabitFingers',
    'keyboardLayout', 'keyboardTypingTime', 'typingSpeed', 'typingAccuracy', 'keyboardApp', 'keyboardFeatures', 'keyboardFeaturesWhy']


export const detailFieldMapping = {
    keyboardType: 'keyboardTypeDetails',
    keyboardApp: 'keyboardAppDetails',
    keyboardFeatures: 'keyboardFeaturesDetails',
    keyboardFeaturesWhy: 'keyboardFeaturesWhyDetails',
    keyboardLayout: 'keyboardLayoutDetails',
};


export const getProgressVal = (isMobile, pageIndex) => {
    const progressMapping = isMobile
        ? [25, 50, 75, 100] // Progress values for mobile
        : [33, 70, 100];    // Progress values for non-mobile

    return progressMapping[pageIndex] || 0; 
};
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './components/App/App';
// import reportWebVitals from './reportWebVitals';
import configureStore from './store/configureStore';
import { Provider as ReduxProvider } from 'react-redux';
import initialState from './store/reducers/initialState';

const store = configureStore(initialState);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
  <ReduxProvider store={store}>
  <App />
  </ReduxProvider>
  </Router>,
);


// reportWebVitals();

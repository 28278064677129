import { useRef, useEffect, useCallback, useState } from "react";

export default function useWebSocket(wsUrl, onMessageCallback) {
  const socketRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [attemptReconnect, setAttemptReconnect] = useState(false);

  const connectWebSocket = useCallback(() => {
    if (!wsUrl) return;

    const ws = new WebSocket(wsUrl);
    socketRef.current = ws;

    ws.onopen = () => {
      // console.log("WebSocket connection established.");
      setIsOpen(true);
      setAttemptReconnect(false); // Reset reconnect flag on successful connection
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log(data, "DATA");
      onMessageCallback(data);
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed.");
      setIsOpen(false);
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
      setAttemptReconnect(true); // Trigger reconnect on error
    };
  }, [wsUrl, onMessageCallback]);

  // useEffect(() => {
  //   // if (!socketRef.current || attemptReconnect) {
  //   if (attemptReconnect) {
  //     connectWebSocket(); // Connect or reconnect when `attemptReconnect` is true
  //   }

  //   return () => {
  //     if (socketRef.current) {
  //       socketRef.current.close();
  //     }
  //   };
  // }, [connectWebSocket, attemptReconnect]);


  // const sendJsonMessage = useCallback(
  //   (payload) => {
  //     if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
  //       socketRef.current.send(JSON.stringify(payload));
  //     } else {
  //       console.warn("WebSocket is not open. Cannot send message.");
  //       setAttemptReconnect(true);
  //     }
  //   },
  //   []
  // );

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [connectWebSocket]);
  
  const sendJsonMessage = useCallback(
    (payload) => {
      if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
        socketRef.current.send(JSON.stringify(payload));
      } else {
        console.warn("WebSocket is not open. Reconnecting...");
        connectWebSocket(); // Reconnect only when a message needs to be sent

        // Wait for connection to open before sending the message
        const waitForOpenAndSend = () => {
          if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
            console.log("Sending message after reconnecting:", payload);
            socketRef.current.send(JSON.stringify(payload));
          } else {
            setTimeout(waitForOpenAndSend, 50); // Retry after a short delay
          }
        };
        waitForOpenAndSend();
      }
    },
    [connectWebSocket]
  );

  const reconnectWs = useCallback(
    () => {
      console.log(socketRef.current, attemptReconnect)
      connectWebSocket(); // Connect or reconnect when `attemptReconnect` is true
    },
    []
  )
  return { sendJsonMessage, isOpen, reconnectWs };
}

import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
import { useSelector } from 'react-redux';
import api from '../../shared/apiSetup'
import { histLayout } from './Util';

const Histogram = ({ pid, isPrevRes, prevResIndex }) => {
  const [histogramData, setHistogramData] = useState({});
  const isMobile = useSelector(state => state.home.isMobileDev);
  const uid = useSelector(state => state.user.uid);

  async function fetchHistogram(pid, isPrevRes) {
    const deviceType = isMobile ? 'mobile' : 'desktop'
    try {
      const response = await api.get(`/result/histogram?uid=${isPrevRes ? pid : uid}&device_type=${deviceType}`); 

      setHistogramData({
        data: response.data,
        maxBin: response.data.maxBin,
        highlightedBinIndex: response.data.highlightedBinIndex
      })
    } catch (error) {
      console.error('Error fetching histogram:', error);
    }
  }

  useEffect(() => {
    fetchHistogram(pid, isPrevRes);
  }, [isPrevRes, prevResIndex]);

  return (
    <div style={{ width: '100%' }}>
      {histogramData.data && (
        <Plot
          style={{ width: '100%' }}
          data={[
            {
              type: 'bar',
              x: histogramData.data.bin_centers,
              y: histogramData.data.hist,
              marker: {
                color: histogramData.data.bin_centers.map((_, index) => {
                  // Highlight the bin containing the specified value
                  return index === Math.ceil(histogramData.highlightedBinIndex) ? '#008080' : '#FAD5A5';
                })
              },
              hoverinfo: 'none',
              text: histogramData.data.hist.map((binHeight, index) => binHeight < 0.5 ? '' : parseInt(histogramData.data.bin_centers[index])), // Display x values on top of bars if bin height is greater than or equal to 1
              textposition: 'outside'
            }
          ]}
          layout={histLayout(histogramData, isMobile)}
        />
      )}
    </div>
  );
};

export default Histogram;

import React from 'react'
import Paper from '@mui/material/Paper'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import classes from './Progress.module.css'

const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: 'black',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: 'white',
    },
}));

const ProgressBar = ({ maxVal, curVal, langData, wpm, errRate, isMob }) => {

    const PerformanceMetric = ({ label, value }) => (
        <div className={classes.wpmWrap}>
            <div className={classes.wpmLabel}>{label}</div>
            <div className={classes.wpmVal}>{value}</div>
        </div>
    );

    return (
        <Paper className={classes.paperStyle}>
            <div className={classes.testPerformanceBar}>
                <div>
                    <div className={classes.testPerformanceBar}>
                        <div className={classes.sentenceLabel}>
                            {langData.sentence}
                        </div>
                        <div className={classes.sentenceProgressVal}>
                            {`${curVal}/${maxVal}`}
                        </div>
                    </div>
                    <BorderLinearProgress variant="determinate" value={(curVal * 100) / 15} />
                </div>
                <PerformanceMetric label="WPM" value={wpm} />
                <PerformanceMetric label={langData.errorrate} value={`${errRate}%`} />
            </div>
        </Paper>
    )
}

export default ProgressBar

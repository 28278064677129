import { useState, useEffect } from "react";
import LineChart from "./LineChart";
import { Chart, registerables } from 'chart.js';
import resultClass from './Results.module.css'
import { lineOptions } from "./Util";

Chart.register(...registerables);

function App({ allWPM, isMobile, isPrevRes, prevResIndex }) {
  const [lineData, setLineData] = useState({
    datasets: [
      {
        label: "",
        data: allWPM.map((value, index) => [index + 1, parseInt(value)]),
        borderColor: "#50AF95",
        borderWidth: 2,
        fill: false, // Set to false to display only the line without filling the area underneath
        legend: {
          display: false,
        },
      },
    ],
  })

  const updateLineData = () => {
    let tempLineData = JSON.parse(JSON.stringify(lineData));
    tempLineData.datasets[0].data = allWPM.map((value, index) => [index + 1, parseInt(value)])
    setLineData(tempLineData)
  }

  useEffect(() => {
    updateLineData()
  }, [allWPM])

  useEffect(() => {
    updateLineData()
  }, [isPrevRes, prevResIndex])


  return (
    <div>
      <div className={resultClass.lineGraphContainer}>
        <LineChart chartData={lineData} options={lineOptions(allWPM)} />
      </div>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Button from '../UI/Button'
import Dialog from '../UI/Dialog'
import { TEST } from '../../constants/RouteConst';
import { Paper } from '@mui/material';
import {
  setIsMobile
} from '../../store/actions/homeActions';
import {
  setAppLang
} from '../../store/actions/userActions';
import { setSentenceList } from '../../store/actions/testActions';
import { setUserData, setExistingCode } from '../../store/actions/userActions';
import { useSelector, useDispatch } from 'react-redux';
import { LANG_MAP } from '../../shared/Utility';
import { arrow_down, arrow_up } from '../UI/Icons'
import Alert from '@mui/material/Alert';
import parse from 'html-react-parser';
import CustomSelect from '../UI/Select/Select';
import Notification from '../UI/Snackbar'
import api from '../../shared/apiSetup'
import homeclass from './Home.module.css'
import Tooltip from '../UI/Tooltip';
import { selectDisplayFields } from '../../shared/Utility'

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useSelector(state => state.home.isMobileDev);
  const appLang = useSelector(state => state.user.appLang);

  const [isDialog, setDialog] = useState(false)
  const [lang, setLang] = useState(appLang)
  const [langData, setLangData] = useState(getLangData(appLang))
  const [inputVal, setInputVal] = useState(null)
  const [continueCode, setContinueCode] = useState(null)
  const [errMsg, setErrMsg] = useState("")
  const [errNotif, setErrNotif] = useState(false)
  const [errInput, setErrInput] = useState(false)
  const [isValidCode, setIsValidCode] = useState(false)
  const [expId, setExpId] = useState("")
  const [showExpIdField, setShowExpIdField] = useState(false)

  function getLangData(lang) {
    return LANG_MAP[lang] || LANG_MAP.English;
  }

  useEffect(() => {
    setLang(appLang) 
    setLangData(getLangData(appLang));
  }, [appLang])


  const handleInvalidURL = msg => {
    navigate('/', { replace: true }); // if the continue code or the personalized link is invalid, redirect to defualt URL 
    setErrNotif(true)
    setIsValidCode(false)
    setErrMsg(msg)// Display Error notification message
  }

  useEffect(() => {
    // Fetch continue code from URL on component mount
    const urlParams = new URLSearchParams(window.location.search);
    const continueCodeFromURL = urlParams.get('continueCode');

    if (continueCodeFromURL) {
      verifyContCode(continueCodeFromURL) // Verify if the continue code is valid
        .then((isValidCode) => {
          if (isValidCode) {
            setContinueCode(continueCodeFromURL) // if valid, store it in the home state
            setIsValidCode(true)
          } else {
            handleInvalidURL("Invalid Continue code: The continue code provided in the URL is invalid or doesn't exist!")
          }
        })
        .catch(() => {

        })
    } 
    // else {
    //   handleInvalidURL("Invalid URL: Please verify the entered URL")
    // }

    function handleResize() {
      const isMobile = window.innerWidth < 768;
      dispatch(setIsMobile(isMobile))
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const contbtnData = {
    btnName: langData.pageData.button,
    width: isMobile ? '100%' : 300,
    height: 50
  }

  const verifyContCode = async (urlCode) => {
    let cc = urlCode ? urlCode : inputVal ? inputVal : 0
    let isContCode = (inputVal && inputVal.length === 6 || urlCode && urlCode.length === 6) ? true : false
    if (cc !== 0 && isContCode) {
      try {
        const response = await api.get(`/home/verifyContinueCode?continueCode=${cc}`);
        dispatch(setExistingCode(true))
        return response.data.isValidCode
      } catch (error) {
        dispatch(setExistingCode(false))
        // setErrMsg("Invalid Continue code!")
        throw error
        // return null
      }
    }
  }

  const handleValidCode = async (code, isValidFlag) => {
    try {
      // If either is missing or invalid, just return early
      if (!code || !isValidFlag) return;

      // Remove some dropdown fields from the questionnaire if the participant has continue code.
      const tempFormVals = await selectDisplayFields(
        appLang,
        code,
        isMobile ? "mobile" : "desktop"
      );

      if (tempFormVals) {
        createNewUid(isValidFlag, tempFormVals);
      }
    } catch (err) {
      console.error("Error selecting display fields:", err);
    }
  }

  const onStartTest = async () => {
    // If there is no continueCode in the input field or the URL, show the Consent Dialog.
    if (!inputVal && !continueCode) {
      setDialog(true);
      return;
    }

    //  If there is a code in the input filed, verify it:
    if (inputVal) {
      let isValid;
      try {
        isValid = await verifyContCode();
      } catch (err) {
        throw err
      }

      // Continue code entered in the input field is invalid
      if (!isValid) {
        if (inputVal) {
          setErrInput(true);
          setErrMsg("Invalid Continue code!"); // Display error message below the input filed 
        }
        setIsValidCode(false);
        return;
      }

      // Continue code entered in the input field is valid
      await handleValidCode(inputVal, isValid);
      return;
    }

    // URL continue code id valid
    if (continueCode && isValidCode) {
      await handleValidCode(continueCode, isValidCode);
    }
  }

  const onCloseDialog = () => {
    setDialog(false)
  }

  const fetchSentences = async () => {
    const appLang = {
      lang: lang
    }
    try {
      const response = await api.post('/home/getSentences', appLang);
      dispatch(setSentenceList(response.data.sentenceList))
      navigate(TEST);
    } catch (error) {
      console.error('Error fetching string array:', error);
    }
  }

  const createNewUid = (isValid, tempFormVals) => {
    const deviceData = {
      device: isMobile ? 'mobile' : 'desktop',
      continueCode: continueCode ? continueCode : inputVal ? inputVal : "",
      browserString: window.navigator.userAgent,
      browserLang: window.navigator.language,
      screenWidth: window.screen.availWidth,
      screenHeight: window.screen.availHeight,
      isContCode: isValid ? true : false,
      experimentId: expId
    }

    api.post('/home/createNewUid', deviceData)
      .then(response => {
        fetchSentences()
        dispatch(setUserData({
          uid: response.data.uid,
          continueCode: response.data.continueCode,
          appLang: appLang,
          formVals: tempFormVals
        }
        ))
      })
      .catch(error => {
        console.error('Error fetching new UID:', error);
      });
  }

  const handleAccept = () => {
    createNewUid(isValidCode)
  }

  function InstructionList() {
    const instructions = [];
    for (let i = 1; i <= 6; i++) {
      const instructionKey = `instructions${i}`;
      const instructionText = langData.instructionsData[instructionKey];
      instructions.push(
        <div key={instructionKey} style={{ marginBottom: 10 }}>
          <strong>{(lang === 'English') ? 'Step' : 'Schritt'} {i}:</strong>
          <div>{parse(instructionText)}</div>
        </div>
      );
    }
    return instructions
  }

  const listValues = ['English', 'Deutsch']

  const onInputChange = (e) => {
    setErrMsg("")
    setErrNotif(false)
    setErrInput(false)
    setInputVal(e.target.value)
  }

  const onExpIdChange = (e) => {
    setExpId(e.target.value)
  }

  const onLangChange = (selectLang) => {
    dispatch(setAppLang(selectLang))
    setLang(selectLang)
    setLangData(getLangData(appLang));
  }

  const InstructionComp = () => (
    <>
      <div className={homeclass.instructionHeading}>{langData.instructionsData.main}</div>
      <div className={homeclass.instructionNote}>{langData.instructionsData.note}</div>
      <Alert sx={{ marginBottom: 5, fontSize: 16 }} icon={false} >
        <div>
          {InstructionList()}
        </div>
      </Alert>
    </>
  )

  const onExpIdClick = () => {
    setShowExpIdField(!showExpIdField)
  }

  return (
    <div className={homeclass.homeContainer}>
      <div className={homeclass.langSelectWrap}>
        <CustomSelect
          listValues={listValues}
          defaultVal={lang}
          onChange={(e) => onLangChange(e.target.value)}
          value={lang}
        />
      </div>
      <div className={homeclass.homeHeader}>{langData.pageData.heading}</div>
      {
        errNotif && (
        <Notification 
        isOpen={true} 
        msg={errMsg} 
        onNotifClose={() => setErrNotif(false)} 
        notifType="error" 
        isAutoHide={false} />
        )
      }
      {isDialog && (
        <Dialog
          handleClose={onCloseDialog}
          handleAccept={handleAccept}
          consentData={langData.consentData}
          consentFormData={langData.consentForm}
        />
      )
      }
      <div className={homeclass.homeContentWrap}>
        <div style={{ width: isMobile ? '' : 'calc(100vw - 10rem)' }} >
          <div className={homeclass.infoWrap}>
            <div>{langData.instructionsData.infobox}</div>
          </div>
          <div>
            {isMobile
              ? (<div> <InstructionComp />  </div>)
              : (<Paper sx={{ padding: 5 }}> <InstructionComp /> </Paper>)
            }
          </div>
        </div>
        {
          !continueCode && (
            <div>
              <div className={homeclass.expIdInputWrap}>
                <input
                  // ref={inputRef}
                  type="text"
                  autoFocus
                  placeholder="Continue Code"
                  style={{
                    width: isMobile ? '96%' : 200,
                    height: 40,
                    fontSize: isMobile ? 32 : 26,
                    fontFamily: 'monospace'
                  }}
                  value={inputVal ? inputVal : ""}
                  onChange={(e) => onInputChange(e)}
                />
                <Tooltip
                  msg="If you leave this field empty, the system will generate a <i>New</i> continue 
                code for you. Please remember to find it on the <b>Results page</b>."
                />
              </div>
              <div className={homeclass.errText}>{errInput && errMsg}</div>
            </div>
          )
        }
        <div className={homeclass.startBtnWrap}>
          <Button
            btnData={contbtnData}
            handleBtnClick={() => onStartTest()}
          />
          <div className={homeclass.expIdText} onClick={() => onExpIdClick()}>
            <div>Enter Experiment ID</div>
            <img src={showExpIdField ? arrow_up : arrow_down} alt="arrow_down" width={15} />
          </div>

          {showExpIdField && (
            <div>
              <div className={homeclass.expIdInputWrap}>
                <input
                  type="text"
                  autoFocus
                  placeholder="Experiment ID"
                  className={homeclass.expIdInput}
                  value={expId}
                  onChange={(e) => onExpIdChange(e)}
                />
              </div>
            </div>
          )
          }
        </div>
      </div>
    </div >
  )
}

export default Home

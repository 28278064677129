import React, { useEffect } from 'react';
import FAQAccordion from './Accordion';
import parse from 'html-react-parser';

export default function AccordionUsage({ faqData, isMobile }) {
    const handleClick = (reference) => {
        // console.log('Clicked on reference:', reference[1]);
        scrollToSection(reference[1]);     
    };

    // Scroll to reference section when a reference is clicked. 
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        // console.log("section", section)
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' }); 
        }
    };

    useEffect(() => {
        const supElements = document.querySelectorAll('sup');
        supElements.forEach((supElement) => {
            supElement.style.cursor = 'pointer';
            const reference = supElement.textContent.trim();
            // console.log(reference, "reference")
            supElement.addEventListener('click', () => handleClick(reference)); // adding click functionalities to the references in FAQ
        });

        // remove event listeners
        return () => {
            supElements.forEach((supElement) => {
                supElement.removeEventListener('click', () => handleClick());
            });
        };
    }, []);


    return (
        <div>
            <div>
                <h2 style={{ color: '#008080' }}>{faqData.heading}</h2>
                {['wpm', 'wpmAge', 'errorRate'].map((key) => (
                    <FAQAccordion
                        key={key}
                        heading={faqData[key].heading}
                        text={faqData[key].text}
                        isMobile={isMobile}
                    />
                ))}
            </div>
            <div>
               <h2 style={{ color: '#008080' }}>References:</h2>
                {Object.entries(faqData.references).map(([key, value]) => {
                    return (<div key={key} id={key}>
                        {parse(value)}
                    </div>
                    )
                }
                )}
            </div>
        </div>
    );
}

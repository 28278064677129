import React from 'react'
import Divider from '@mui/material/Divider';
import resultClass from './Results.module.css'

const ResultHighlights = ({ resHighData, histogramData, isMobile, isPrevRes }) => {

    return (
        <div className={resultClass.resHighContainer} 
        style={{ 
            padding: (isPrevRes && isMobile || isMobile) ? '30px 0px' : isPrevRes ? '30px 280px' : '30px 70px' }}>
            {resHighData.map((each, index) => (
                <>
                    <div className={resultClass.resHighEleWrap}>
                        <div className={resultClass.resHighEleHeading}>{each.heading}</div>
                        <div className={resultClass.errWpmWrap}>
                            <div className={resultClass.errWpmVal}>
                                {each.errors}: <span style={{ color: 'black' }}>{each.errVal}</span>
                            </div>
                            <div className={resultClass.errWpmVal} style={{ marginLeft: 20 }}>
                                {each.wpm}: <span style={{ color: 'black' }}>{each.wpmVal}</span>
                            </div>
                        </div>
                    </div>
                    <div className={resultClass.resHighSentence}>{each.sentence}</div>
                    {
                        (index === 2) && <div className={resultClass.resHighSentence}>({histogramData.maxErrRate[1]})</div>
                    }
                    {(index < 2) && <Divider variant="middle" sx={{ marginTop: 3, marginBottom: 2 }} />}
                </>
            ))}
        </div>
    )
}

export default ResultHighlights

import * as actionTypes from '../actions/actionTypes';
import { updateObject, getDefaultLang } from '../../shared/Utility';
import initialState from './initialState';

export const setIsMobile = (state, action) => {
  return (
    updateObject(state, {
      isMobileDev: action.data,
    })
  )
};

// export const setSystemLang = (state, action) => {
//   // const result = getDefaultLang(action.data)
//   return (
//     updateObject(state, {
//       systemLang: action.data,
//     })
//   )
// };

const homeReducer = (state = initialState.home, action) => {
  switch (action.type) {
    case actionTypes.SET_IS_MOBILE: return setIsMobile(state, action);
    // case actionTypes.SET_SYSTEM_LANGUAGE: return setSystemLang(state, action);
    default:
      return state;
  }
};

export default homeReducer;

import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import parse from 'html-react-parser';

const FAQAccordion = ({heading, text, isMobile}) => {
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${heading}-content`}
                id={`${heading}-header`}
                sx={{ fontWeight: 'bold' }}
            >
                {heading}
            </AccordionSummary>
            <AccordionDetails sx={{ fontSize: isMobile ? 16 : 18 }}>
                {parse(text)}
            </AccordionDetails>
        </Accordion>
    )
}

export default FAQAccordion
import langs from 'langs';
import { languageMapping_de, languageMapping_en } from './Lang/LangList';
import { formTextLables_de, formTextLables_en } from './Lang/QuestionnaireText';
import api from './apiSetup'
import {
  instructionsData_de, consentData_de, instructionsData_en,
  consentData_en, pageData_en, pageData_de, consentForm_de, consentForm_en
} from './Lang/HomeTxt'

// export const getDefaultLang = (systemLang) => {
//   // Extract the language code from the system language
//   const languageCode = systemLang.split('-')[0];

//   // Use the langs library to get the language name from the code
//   const languageInfo = langs.where('1', languageCode);

//   // If languageInfo is found, return the language name; otherwise, return a default language
//   return languageInfo ? languageInfo.name : "English"; // You can set a default language here
// };


export const getGermanLanguageName = () => {
  const lang = window.navigator.language;
  if (lang.includes("en")) {
    return "Englisch";
  }
  return languageMapping_de[lang] || "Deutsch";
}

export const getEnglishLanguageName = () => {
  const lang = window.navigator.language;
  if (lang.includes("en")) {
    return "English";
  }
  return languageMapping_en[lang] || "English";
}

export const selectDisplayFields = async (appLang, cc, device) => {
  const formTextLables = (appLang === 'English') ? formTextLables_en : formTextLables_de
  const copyFormTxtLabels = JSON.parse(JSON.stringify(formTextLables))
  const elementsToRemove = ['age', 'gender', 'nativeLanguages']
  let tempArray = copyFormTxtLabels.keyboardData

  try {
    const response = await api.get(`/home/isTestTaken?continueCode=${cc}&device_type=${device}`);
    if (response.data.testTaken === true) {
      tempArray = copyFormTxtLabels.keyboardData.filter(eachObj => eachObj.label !== 'keyboardTypingTime')
    }
  } catch (error) {
    throw error
  }

  const updatedArray = copyFormTxtLabels.userData.filter(eachObj => !elementsToRemove.includes(eachObj.label))

  copyFormTxtLabels.userData = updatedArray
  copyFormTxtLabels.keyboardData = tempArray
  // console.log(copyFormTxtLabels, "BEFORE RETURN")
  return (copyFormTxtLabels)
}

export const updateObject = (oldObject, updatedProperties) => ({
  ...oldObject,
  ...updatedProperties
});



// HOME utility

export const LANG_MAP = {
  English: {
    instructionsData: instructionsData_en,
    consentData: consentData_en,
    pageData: pageData_en,
    consentForm: consentForm_en,
  },
  Deutsch: {
    instructionsData: instructionsData_de,
    consentData: consentData_de,
    pageData: pageData_de,
    consentForm: consentForm_de,
  },
};


//Test utility

//TODO: stringify is not working. It currenlty returns an empty object string. 

const getScreenOrientation = () => {
  const screenOrientation = window.screen.orientation;
  if (screenOrientation !== "undefined") {
    return screenOrientation.angle
    // return JSON.stringify(screenOrientation);
  }
  // NOTE: DEPRECATED CODE 
  // if (typeof(window.orientation) != "undefined") {
  //     return window.orientation;
  // }
  else {
    return -1;
  }
}

function whichPressed(keys) {
  var pressed = [];
  for (var k in keys) {
    if (keys[k] === true) {
      pressed.push(k);
    }
  }
  return pressed;
}

export const getEvtData = (testSecId, event, time, input, keys, inputEvt) => {
  if (testSecId) {
    const evtObj = {
      testSectionId: testSecId,
      eventTimestamp: time,
      eventType: String(event.type == null ? '' : event.type), // catches both null and undefined
      eventKey: String(event.key == null ? '' : event.key), // catches both null and undefined
      eventCode: String(event.code == null ? '' : event.code), // catches both null and undefined
      eventData: inputEvt ? String(event.nativeEvent.data == null ? '' : event.nativeEvent.data) : String(event.data == null ? '' : event.data), // catches both null and undefined
      inputText: String(input),
      // DOUBT
      keyCodes: String(whichPressed(keys)),
      screenOrientation: getScreenOrientation(),
    }
    return evtObj
  } else {
    // Error - test section id is null
  }
}


export const calculateAverage = (arr) => {
  if (arr.length === 0) {
      return 0; // Handle the case where the array is empty to avoid division by zero.
  }

  // Use the reduce function to sum up all elements in the array.
  var sum = arr.reduce(function (accumulator, currentValue) {
      return accumulator + currentValue;
  }, 0);

  // Calculate the average by dividing the sum by the number of elements.
  var average = sum / arr.length;
  return average;
}
